/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:252cc7bc-9cfd-4e4c-a7a5-129f7d46b740",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_CNXvKEDZa",
    "aws_user_pools_web_client_id": "3mg52ilg03n485a296qge3iknd",
    "oauth": {}
};


export default awsmobile;
