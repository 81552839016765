<template>
    <v-main>
     Seth
   </v-main> 
 
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'


export default {
  name: 'Home',
  components: {
    // HelloWorld
  }
}
</script>
