<template>
  <div>
    <v-app>
      <v-app-bar  
        color="Secondary"
        dark
        app
      >
      <v-app-bar-nav-icon></v-app-bar-nav-icon>
      <v-toolbar-title>Seth T Designs</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-on:click="openLogin()" v-if="!isLoggedIn">
        <v-icon>login</v-icon>
      </v-btn>
      <amplify-sign-out v-if="isLoggedIn"></amplify-sign-out>
      </v-app-bar>
      <v-dialog v-model="loginScreen" fullscreen>
          <amplify-authenticator>
            
          </amplify-authenticator>
      </v-dialog>
      <v-main>
        <v-container grid-list-xs>
          <imageGallery></imageGallery>
          More pictures coming soon...
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Amplify from 'aws-amplify';
import ImageGallery from '@/components/ImageGallery.vue';
export default {
  name: 'App',
  data: () => ({
    loginScreen: false,
    isLoggedIn: false
  }),
  components: {
    ImageGallery
  },
  computed: {  
    userName: function() {
      if(this.isLoggedIn != null){
        return 'Logged in as: '+ this.$store.state.userInfo.username + '  ';
      } else {
        return ''
      }
    }
  },
  methods: {
    openLogin: function() {
      this.loginScreen = !this.loginScreen;
    }
  },
  async mounted() {
    const currentUser = await Amplify.Auth.currentUserInfo();
    if (currentUser != null){
      this.isLoggedIn = true;
      this.$store.commit('saveUserInfo',currentUser);
    }   
  }
};
</script>
